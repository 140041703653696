.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 20px 0;
    padding: 10px;
}

.pagination-item,
.pagination-arrow {
    min-width: 36px;
    height: 36px;
    border: 1px solid #e0e0e0;
    background: white;
    color: #333;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s ease;
}

.pagination-arrow {
    font-size: 20px;
    padding: 0 12px;
}

.pagination-item:hover:not(.active):not(.dots):not(:disabled),
.pagination-arrow:hover:not(:disabled) {
    background-color: #f5f5f5;
    border-color: #27AE60;
    color: #27AE60;
}

.pagination-item.active {
    background-color: #27AE60;
    border-color: #27AE60;
    color: white;
    cursor: default;
}

.pagination-item.dots {
    border: none;
    cursor: default;
    padding: 0 4px;
}

.pagination-item:disabled,
.pagination-arrow:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: #f5f5f5;
}
