.login { 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    min-height: 100vh; 
    background: linear-gradient(135deg, rgba(74, 229, 74, 0.8) 0%, rgba(74, 229, 74, 0.6) 100%);
    & .contentLogin{ 
        width: 100%; 
        max-width: 400px; 
        padding: 20px;
        & .panel{ 
            background: #FFFFFF; 
            border-radius: 12px; 
            padding: 40px; 
            box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1); 
            text-align: center;
            & img{ 
                margin-bottom: 30px; 
            }
            & .acessaForm{ 
                display: flex; 
                flex-direction: column; 
                gap: 16px;
                & .btn.entrar{ 
                    background: #000000; 
                    color: #FFFFFF; 
                    font-weight: 500; 
                    padding: 12px; 
                    margin-top: 8px; 
                    transition: background-color 0.3s ease;
                    &:hover{ 
                        background: darken(#000000, 5%); 
                    }
                }
                & .btn.cadastro{ 
                    color: #333333; 
                    font-size: 14px; 
                    margin-top: 8px; 
                    text-decoration: none; 
                    transition: color 0.3s ease;
                    &:hover{ 
                        color: darken(#333333, 10%); 
                    }
                }
            }
        }
    }
}

.dashboard{
    & h1{ font-size: 30px; color: #999999; margin-bottom: 0; }
    & p.descricao{ font-size: 12px; color: #999999; margin-top: 5px; margin-bottom: 23px; }
    & .rowCenter{ flex-wrap:wrap;
        & .panel{ max-width: 555px; margin-right: 10px; width:calc(33.33% - 10px); -moz-box-sizing:border-box; -webkit-box-sizing:border-box; box-sizing:border-box;
            & p{ font-weight: normal; font-size: 18px; letter-spacing: -0.33px; color: var(--cinzaPreto); margin-top: 5px; margin-bottom: 9px; }
            & h3{ font-weight: bold; font-size: 30px; letter-spacing: -0.33px; margin-top: 0; margin-bottom: 0; 
                &.cinza{ color: var(--cinza); }
            }
            &:last-child{ margin-right: unset; }
        }
    }
    & .panel{
        & .header{ display: flex; align-items: center;
            & .filtros{ margin-bottom: 32px;
                & button{ border: 1px solid var(--preto); color: var(--preto); font-size: 15px; width: 120px; border-radius: 20px; margin-right: 20px; }
                & button.active, & button:hover{ background-color: var(--preto); color: var(--branco); }
            }
            & .subtitle{ margin-left: auto; text-align: right; display: flex; align-items: center;
                span{ width: 15px; height: 14px; margin-right: 3px; 
                    &.green{ background-color: var(--verdeExoss); }
                    &.red{ background-color: var(--vermelho); }
                }
                p{ font-weight: 500; font-size: 14px; margin-right: 13px;  margin-top: 0; margin-bottom: 0; }
            }
        }
    }
    & .multiGraph{ display: flex; 
        & .panel{ display: flex; flex-direction: column; width: 50vw;
            & p.titulo{ font-size: 16px; font-weight: bold; color: var(--azulEscuro); }
            & canvas{ margin-top: auto; }
        }
        & .panel.mr{ margin-right: 60px; }
    }
}

.digitalAccounts{
    p.totalResults{ font-weight: 500; font-size: 16px; color: var(--cinzaEscuro); margin-top: 40px; }
    & .headerBtn button{ color: #000000; border-color: #000000; }
}

.transfer, .cardStatement{
    & .info{ margin-right: 31px;
        & p{ font-size: 14px; letter-spacing: -0.33px; margin-bottom: 7px; color: var(--cinzaEscuro); }
        & h3{ font-weight: 500; font-size: 16px; letter-spacing: -0.33px; margin-top: 0; color: var(--cinzaEscuro);  }
    }
    & .multiInput button{ border: 1px solid #000000; width: 118px; height: 46px; margin-top: -6px; font-weight: 500; font-size: 16px; color: #000000; }
    & .btnAction{ width: 235px; background-color: #000000; text-transform: uppercase; float: right; }
}

.cardStatement{
    & .headerBtn button{ border-color: #000000; color: #000000; }
}

// Estilos específicos para formulários
.form-group {
    margin-bottom: 20px;

    &.text-center {
        text-align: center;
    }

    label {
        display: block;
        margin-bottom: 8px;
        font-weight: 500;
        color: #333;
    }

    .form-control {
        width: 100%;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 16px;
        background-color: #fff;

        &:focus {
            outline: none;
            border-color: #4AE54A;
        }

        &:hover {
            border-color: #4AE54A;
        }
    }

    select.form-control {
        appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 1em;
        padding-right: 40px;
    }

    textarea.form-control {
        min-height: 100px;
        resize: vertical;
    }

    .form-text {
        margin-top: 5px;
        font-size: 14px;
        color: #666;
    }
}

// Grids
.form-row {
    display: flex;
    margin-right: -10px;
    margin-left: -10px;
    flex-wrap: wrap;

    .form-group {
        padding-right: 10px;
        padding-left: 10px;
        width: 100%;

        &.col-4 {
            width: 33.333333%;
        }

        &.col-8 {
            width: 66.666667%;
        }
    }

    @media (max-width: 768px) {
        margin-right: -5px;
        margin-left: -5px;

        .form-group {
            padding-right: 5px;
            padding-left: 5px;
        }
    }
}

// Menu hambúrguer
.hamburger-menu {
    position: fixed;
    top: 15px;
    left: 15px;
    z-index: 996;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    display: none;
    width: 30px;
    height: 25px;

    span {
        display: block;
        width: 100%;
        height: 3px;
        background-color: var(--corPadrao);
        margin: 5px 0;
        transition: 0.3s;
        border-radius: 3px;
    }

    @media (max-width: 768px) {
        display: block;
    }
}

.closeMenu {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    z-index: 997;
    width: 30px;
    height: 25px;

    span {
        display: block;
        width: 100%;
        height: 3px;
        background-color: #fff;
        margin: 5px 0;
        transition: 0.3s;
        border-radius: 3px;

        &:first-child {
            transform: rotate(45deg) translate(7px, 7px);
        }

        &:nth-child(2) {
            opacity: 0;
        }

        &:last-child {
            transform: rotate(-45deg) translate(7px, -7px);
        }
    }
}

// Modais responsivos
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 997;
    padding: 0;

    .modalContent {
        background: #fff;
        border-radius: 8px;
        width: 100%;
        max-width: 500px;
        height: 90%;;
        position: relative;
        display: flex;
        flex-direction: column;

        @media (max-width: 768px) {
            margin: 0;
            max-width: 100%;
            height: 100vh;
            border-radius: 0;
        }

        .modalHeader {
            padding: 20px;
            border-bottom: 1px solid #eee;
            background: #fff;
            border-radius: 8px 8px 0 0;
            position: relative;

            h3 {
                margin: 0;
                font-size: 1.25rem;
                font-weight: 600;
                color: #333;
                text-align: center;
            }

            .closeButton {
                background: none;
                border: none;
                font-size: 24px;
                color: #666;
                cursor: pointer;
                padding: 0;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 50%;
                transition: all 0.2s;

                &:hover {
                    background: #f0f0f0;
                    color: #333;
                }
            }
        }

        .modalBody {
            flex: 1;
            overflow-y: auto;
            padding: 20px;
            position: relative;

            .form-group {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }

                label {
                    display: block;
                    margin-bottom: 8px;
                    font-weight: 500;
                    color: #333;
                }

                .form-control {
                    width: 100%;
                    padding: 10px;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    font-size: 1rem;

                    &:focus {
                        outline: none;
                        border-color: #4AE54A;
                    }

                    &:hover {
                        border-color: #4AE54A;
                    }
                }

                .form-control-static {
                    padding: 15px;
                    background: #f8f9fa;
                    border-radius: 4px;
                    color: #333;

                    p {
                        margin: 8px 0;
                        &:first-child { margin-top: 0; }
                        &:last-child { margin-bottom: 0; }
                    }
                }
            }
        }

        .modalFooter {
            margin-top: 20px;
            padding: 20px 0;
            border-top: 1px solid #eee;
            display: flex;
            justify-content: center;
            gap: 10px;

            .btn {
                min-width: 120px;
                padding: 10px 20px;
                border-radius: 4px;
                font-weight: 500;
                
                &.primary {
                    background: #000;
                    color: #fff;
                    border: none;
                    
                    &:hover {
                        background: #333;
                    }
                }
                
                &.secondary {
                    background: #fff;
                    color: #000;
                    border: 1px solid #000;
                    
                    &:hover {
                        background: #f5f5f5;
                    }
                }
            }

            button {
                position: relative;

                .tooltip {
                    position: absolute;
                    bottom: calc(100% + 10px);
                    left: 50%;
                    transform: translateX(-50%);
                    background: #333;
                    color: white;
                    padding: 8px 12px;
                    border-radius: 4px;
                    font-size: 14px;
                    font-weight: normal;
                    white-space: nowrap;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 0.3s ease;
                    z-index: 995;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transform: translateX(-50%);
                        border: 6px solid transparent;
                        border-top-color: #333;
                    }

                    &.show {
                        opacity: 1;
                        visibility: visible;
                    }

                    &.fade-out {
                        opacity: 0;
                        visibility: hidden;
                        transition: opacity 0.3s ease, visibility 0s linear 0.3s;
                    }
                }
            }
        }
    }
}

// Ajustando z-index do menu
.menu {
    z-index: 994;
}

// Tabelas responsivas
.table-responsive {
    width: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    table {
        width: 100%;
        border-collapse: collapse;
        
        @media (max-width: 768px) {
            display: block;
            
            thead {
                display: none;
            }

            tbody {
                display: block;
                width: 100%;

                tr {
                    display: block;
                    margin-bottom: 15px;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    background: #fff;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
                    width: 100%;

                    td {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px;
                        border: none;
                        border-bottom: 1px solid #eee;
                        width: 100%;
                        
                        &:before {
                            content: attr(data-label);
                            font-weight: 600;
                            padding-right: 10px;
                        }

                        &:last-child {
                            border-bottom: none;
                        }

                        &.actions {
                            justify-content: flex-end;
                            padding: 10px;
                            background: #f8f9fa;
                            
                            &:before {
                                display: none;
                            }

                            .btn.icon {
                                display: inline-flex;
                                align-items: center;
                                justify-content: center;
                                width: 35px;
                                height: 35px;
                                padding: 0;
                                margin: 0 5px;
                                border-radius: 50%;
                                background: #fff;
                                border: 1px solid #ddd;

                                img {
                                    width: 18px;
                                    height: 18px;
                                }

                                &.danger {
                                    border-color: var(--danger-color);
                                    background: #fff;
                                    
                                    &:hover {
                                        background: var(--danger-color);
                                        img {
                                            filter: brightness(0) invert(1);
                                        }
                                    }
                                }

                                &:hover {
                                    background: var(--primary-color);
                                    border-color: var(--primary-color);
                                    img {
                                        filter: brightness(0) invert(1);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Container responsivo
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
    overflow-x: hidden;
}

// Panel responsivo
.panel {
    margin: 15px 0;
    padding: 15px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    overflow-x: hidden;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: stretch;

            h2 {
                margin-bottom: 15px;
            }

            .btn {
                width: 100%;
            }
        }
    }

    .panelBody {
        overflow-x: hidden;
    }
}

// Regras de Cobrança
.regras {
    .headerBtn {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
            h1 {
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 5px;
                text-transform: capitalize;
            }

            .descricao {
                color: #666;
                font-size: 14px;
            }
        }
    }

    .panel {
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .panelBody {
            padding: 20px;

            .table-responsive {
                margin-bottom: 20px;

                table {
                    border-collapse: collapse;

                    th, td {
                        padding: 12px;
                        text-align: left;
                        border-bottom: 1px solid #eee;

                        &.actions {
                            text-align: right;
                            white-space: nowrap;

                            .btn.icon {
                                margin-left: 8px;

                                &:first-child {
                                    margin-left: 0;
                                }
                            }
                        }
                    }

                    th {
                        font-weight: 600;
                        color: #333;
                    }

                    td {
                        color: #666;
                        width: 93%;
                    }

                    tbody tr:hover {
                        background-color: #f9f9f9;
                    }
                }
            }
        }
    }
}