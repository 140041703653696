.dashboard .rowCenter {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 30px;
}

.dashboard .rowCenter .panel {
    flex: 1;
    width: 50% !important;
}

.dashboard .panel {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
}

.dashboard .panel p {
    font-size: 14px;
    color: #828282;
    margin-bottom: 10px;
}

.dashboard .panel h3 {
    font-size: 24px;
    color: #27AE60;
    margin: 0;
}

.dashboard .panel h3.cinza {
    color: #4F4F4F;
}

@media screen and (max-width: 768px) {
    .dashboard .rowCenter {
        flex-direction: column;
        gap: 15px;
    }

    .dashboard .rowCenter .panel {
        width: 100% !important;
    }

    .dashboard .panel {
        padding: 15px;
    }

    .dashboard .panel h3 {
        font-size: 20px;
    }
}

@media screen and (max-width: 480px) {
    .dashboard .headerBtn {
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
    }

    .dashboard .headerBtn button {
        width: 100%;
    }

    .dashboard .panel {
        padding: 12px;
    }

    .dashboard .panel h3 {
        font-size: 18px;
    }
}
