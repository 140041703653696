.btn {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    border: 1px solid transparent;
}

.btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-primary {
    background-color: #34D175;
    color: white;
}

.btn-primary:hover {
    background-color: #2bb564;
}

.btn-outline-secondary {
    background-color: transparent;
    border-color: #6c757d;
    color: #6c757d;
}

.btn-outline-secondary:hover {
    background-color: #6c757d;
    color: white;
}

.btn-link {
    background: none;
    border: none;
    color: #34D175;
    padding: 0;
    text-decoration: underline;
}

.btn-link:hover {
    color: #2bb564;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #374151;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #374151;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out;
}

.form-control:focus {
    border-color: #34D175;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 209, 117, 0.25);
}

.form-checkbox {
    margin-right: 0.5rem;
}
