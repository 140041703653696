.filtros-container {
    display: flex;
    align-items: flex-end;
    gap: 20px;
}

.filtro-grupo {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.filtro-grupo label {
    font-weight: 500;
    color: #4F4F4F;
}

.filtros {
    display: flex;
    gap: 10px;
    align-items: center;
}

.rowCenter.period {
    display: flex;
    align-items: center;
    gap: 10px;
}

.rowCenter.period p {
    margin: 0;
    padding-top: 15px;
}

.btnFiltrar {
    align-self: flex-end;
    margin-top: 10px;
}

@media screen and (max-width: 768px) {
    .filtros-container {
        flex-direction: column;
        align-items: stretch;
        gap: 15px;
    }

    .filtros-container > * {
        width: 100% !important;
        max-width: 100% !important;
        margin: 0 !important;
    }

    .filtros {
        flex-direction: column;
        width: 100%;
    }

    .filtros button,
    .filtros input,
    .filtros select {
        width: 100%;
    }

    .rowCenter.period {
        flex-direction: column;
        align-items: stretch;
    }

    .rowCenter.period p {
        text-align: center;
        padding: 5px 0;
    }

    .btnFiltrar {
        width: 100%;
        margin-top: 20px;
    }
}
