@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

*, body > * { font-family: 'Roboto' !important; }
body { background-color: #F8F9FB; padding: 0; margin: 0; }
a{ text-decoration: none; }
button{ outline: none; cursor: pointer; box-sizing: border-box; border: none; background-color: transparent; }
button.btn,a.btn{ padding: 10px 0; font-size: 16px; border-radius: 8px; transition: all 0.3s ease; text-align: center; }

select, input{ 
    outline: none;
    width: 100%;
    height: 44px;
    background: var(--branco);
    color: #4F4F4F;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 16px;
    padding: 10px 15px;
    transition: all 0.3s ease;
    
    &:focus {
        border-color: var(--verdeExoss);
        box-shadow: 0 0 0 2px rgba(68, 218, 106, 0.1);
    }
    
    &:hover {
        border-color: var(--corPadrao);
    }
    
    &.disabled{ 
        background-color: #F8F9FB;
        border-color: #E0E0E0;
        cursor: not-allowed;
        opacity: 0.7;
    }
}

select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%234F4F4F' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 15px center;
    padding-right: 40px;
    cursor: pointer;
}

input::placeholder, select::placeholder{ 
    color: #BDBDBD;
    opacity: 0.8;
}

.required {
    color: #FF4D4F;
    margin-left: 4px;
}

.inputDefault{ 
    display: flex; 
    flex-direction: column; 
    flex-grow: 1;
    margin-bottom: 16px;
    
    label {
        font-size: 14px;
        color: #4F4F4F;
        margin-bottom: 8px;
        font-weight: 500;
    }
}

.rowCenter{ display: flex; flex-direction: row; align-items: center; 
    &.period{
        & p{ margin: 49px 12px 0 14px; text-transform: uppercase; font-size: 14px; letter-spacing: -0.33px; }
    }
}
.multiInput{ display: flex; flex-direction: row; align-items: center; flex-wrap: wrap;
    & div.inputDefault{ margin-bottom: 27px; margin-right: 31px;
        &:last-child{ margin-right: unset; }
    }
}

// CUSTOM
:root{
    --azulEscuro: #2F3A59;
    --azulMedio: #3E4F74;
    --rosaEscuro: #C04E7A;
    --vermelho: #EB5757;
    --laranja: #F3945D;
    --cinza: #A7A8AD;
    --cinzaMedio: #999999;
    --cinzaMedioEscuro: #888888;
    --cinzaPreto: #333333;
    --cinzaEscuro: #555555;
    --preto: #000000;
    --verdeClaro: #6FCF97;
    --corPadrao: #44DA6A;
    --verdeExoss: #44DA6A;
    --branco: #FFFFFF;
}
.body{ margin-left: 240px; }

.red{ color: var(--vermelho) !important; }
.green{ color: var(--verdeExoss) !important; }

.sideMenuBar{ 
    width: 240px; 
    height: 100vh; 
    position: fixed; 
    left: 0; 
    background-color: var(--corPadrao);
    overflow-y: auto;
    
    & .profile{ 
        margin: 31px 25px 38px; 
        text-align: center;
        & p{ 
            font-size: 16px; 
            font-weight: bold; 
            margin-top: 20.49px; 
            color: var(--branco); 
        }
    }
    & .navigation{ 
        & ul{ 
            list-style: none; 
            padding: 0; 
            margin: 25px 25px 0 25px;
            & a{ 
                color: var(--preto); 
                display: flex; 
                padding: 13px 0 13px 10px; 
                border-bottom: 1px solid rgba(255, 255, 255, 0.3); 
                font-weight: 500; 
                font-size: 16px; 
                align-items: center;
                & img{ margin-right: 15px; }
                &:hover, &:focus{ 
                    background-color: rgba(255, 255, 255, 0.1); 
                    border-radius: 4px; 
                }
            }
            & a.submenu { 
                padding-left: 40px; 
                font-size: 14px; 
                color: #333333; 
            }
            & a.active{ 
                background-color: rgba(255, 255, 255, 0.3); 
                border-bottom: none; 
                border-radius: 4px; 
            }
            & a.noBorder{ 
                border-bottom: none; 
            }
        }
    }
}

.navbar { display: flex; align-items: center; background-color: var(--branco); padding: 2px 0; box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
    & .barLogo { margin-left: 33px;
        & img { width: 103px; }
    }
    & .barLinks{ margin-left: auto; margin-right: 33px;
        & ul{ list-style: none; padding: 0;
            & a{ display: flex; align-items: center; color: #4F4F4F; font-size: 14px; font-weight: bold;
                & img{ margin-left: 7px; }
            }
        }
    }
}

.footer { flex: 0; text-align: center; background-color: #EEEEEE; padding: 2.5px 20px; position: fixed; width: 100%; bottom: 0;
    & p { font-size: 12px; color: #999999; }
}

.paginacao{
    & button{ font-size: 18px; color: var(--azulEscuro); margin-left: 11px; padding: 10.5px 16.6px; font-weight: bold; border: 1px solid #E0E0E0; 
        box-sizing: border-box; border-radius: 5px; transition: .2s; box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04); background: var(--branco);
    }
    & button.disabled, button.disabled:hover{ color: #999; border: 1px solid #999999; cursor: default; }
    & button.active, button:hover{ color: var(--corPadrao); border: 1px solid var(--corPadrao); }
}

.table { border-collapse: collapse; width: 100%;
    & td, th{ border-bottom: 1px solid #DFDFDF; padding: 11px 20px; }
    & td{ border-bottom: 1px solid #DFDFDF; font-size: 15px; color: #4F4F4F; 
        &.tag{
            & span{ padding: 3px 8px; border-radius: 4px; display: block; width: 71px; font-weight: bold; font-size: 12px; text-align: center; 
                position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);
            }
        }
    }
    & th{ font-size: 15px; color: var(--corPadrao); text-align: left; font-weight: normal; }
}

input[type="checkbox"].checkBoxtable{ cursor: pointer; -webkit-appearance: none; appearance: none; background: transparent; border: 1px solid #999999; border-radius: 2px; position: relative; width: 17px; height: 17px; transition: all .1s linear; padding-left: 0 !important;
    &:checked{ background-color: var(--vermelho); }
    &:focus{ outline: 0 none; box-shadow: none; }
}

input[type="file"]{ display: none; }

.titlePanel{ font-weight: bold; font-size: 22px; color: var(--cinzaMedioEscuro); margin-top: 30px; margin-bottom: 7px; }
.panel{ background-color: var(--branco); padding: 24px 30px 27px 29px; margin-bottom: 30px; box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04); border-radius: 5px; 
    & .panelHeader{  border-bottom: 1px solid #DFDFDF; margin-bottom: 18.5px;
        & h3{ color: var(--corPadrao); font-size: 16px; font-weight: bold; margin: 0; padding-left: 4px; padding-bottom: 7px; }
    }
}

.mgDefault{ margin: 44px 40px 80px; }
.headerBtn{ display: flex; align-items: center; margin-bottom: 23px;
    & h1{ font-size: 30px; color: var(--cinzaMedio); margin-bottom: 0; }
    & p.descricao{ font-size: 12px; color: var(--cinzaMedio); margin-top: 5px; }
    & button{ margin-left: auto; border: 1px solid var(--cinzaPreto); width: 175px; font-weight: 500; font-size: 16px; color: var(--cinzaPreto); background-color: var(--branco); }
 }
.textEmpty{ color: #ABABAB; font-size: 20px }
.modal, .alert{
    p{ font-size: 16px; 
        &.warning{ text-transform: uppercase; color: var(--cinzaMedio); font-weight: bold; }
        &.description{ margin-top: 23px; margin-bottom: 21px; }
        &.subText{ margin-top: -13px; margin-bottom: 16px; color: var(--cinza); font-size: 12px; }
    }
    .btn.alert{ background-color: var(--corPadrao); color: var(--preto); width: 100%; text-transform: uppercase; font-weight: 500; font-size: 16px; }
    & .boletoGerado{ display: flex; flex-direction: row;
        & input{ border-top-right-radius: 0; border-bottom-right-radius: 0; }
        & button{ padding: 5.5px; border-top-right-radius: 2px; border-bottom-right-radius: 2px; border: 1px solid #dfdfdf; border-left: none; }
    }
    & p.close{ text-align: right; font-size: 25px; margin-top: 0; margin-bottom: 0; cursor: pointer; }
    & button.visualizaBoleto{ width: 100%; background-color: var(--azulMedio); color: var(--branco); font-weight: 500; margin-top: 15px; }
}
.modal.warning{
    & p{
        &:first-child{ font-size: 16px; color: var(--cinzaMedio); text-transform: uppercase; font-weight: bold; margin-bottom: 22px }
        &:last-child{ margin-top: 23px; margin-bottom: 22px; font-size: 16px }
    }
}
.cancelamentoAcao{ display: flex; align-items: center; margin-top: 27px;
    button{ width: 50vw; background-color: var(--vermelho); color: var(--branco); text-transform: uppercase; font-size: 16px; font-weight: 500;
        &:first-child{ margin-right: 20px; }
        &:last-child{ background-color: var(--cinzaMedio); }
    }
}
.btnVoltar{ border: 1px solid #BDBDBD; color: var(--cinzaMedio); padding-left: 32px !important; padding-right: 32px !important;
    &:hover{ background-color: #D2D2D2; }
}
.acoes-footer{ display: flex; flex-direction: row; align-items: center;
    & .btnAcao{ margin-left: auto; background-color: var(--corPadrao); color: var(--preto); padding-left: 10px; padding-right: 10px; text-transform: uppercase; min-width: 175px; }
}
.modalConfirm{
    & h3{ font-weight: bold; font-size: 16px; color: var(--cinzaMedio); text-transform: uppercase; }
    & p{ font-size: 16px; }
    & .btnCancelar{ margin-top: 15px; color: var(--cinza); font-size: 16px; }
}
.btnFiltrar, .btnConfirm{ background-color: var(--corPadrao); color: var(--preto); width: 175px; height: 45px; margin-top: 19px; margin-left: 24px; font-weight: 500; text-transform: uppercase; }
.btnConfirm{ width: 100%; margin-left: 0; }

.footerWithPag{ display:flex; }
.footerWithPag > .footerActions{ flex: 1 }
.footerWithPag > .footerActions > a{ max-width:100px; display:block }

.btn-salvar {
    background-color: var(--corPadrao);
    color: var(--branco);
    padding: 12px 40px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    transition: all 0.3s ease;
    margin-top: 20px;
    width: auto;
    display: inline-block;

    &:hover {
        background-color: darken(#44DA6A, 5%);
    }

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
}

.upload-field {
    border: 2px dashed #E0E0E0;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-bottom: 20px;

    &:hover {
        border-color: var(--corPadrao);
    }

    input[type="file"] {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 1;
    }

    .preview-image {
        max-width: 200px;
        margin-top: 10px;
    }
}