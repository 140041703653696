.hamburger-menu {
    display: none;
    position: fixed;
    top: 6px;
    left: 20px;
    z-index: 995;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
}

.hamburger-menu span {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px 0;
    background: #27AE60;
    transition: all 0.3s ease;
}

.sideMenuBar {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    background: #34D175;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 994;
}

@media screen and (max-width: 570px) {
    .hamburger-menu {
        display: block;
    }

    .sideMenuBar {
        display: none;
        transform: translateX(-100%);
    }

    .sideMenuBar.active {
        display: block;
        animation: slideIn 0.3s forwards;
    }

    @keyframes slideIn {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(0);
        }
    }

    .menu-overlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 993;
    }

    .menu-overlay.active {
        display: block;
    }
}
