@media (min-width: 425px) and (max-width: 1024px){
    .dashboard .panel .header .btn{ margin-bottom: 10px; }
    .dashboard .multiGraph{ flex-direction: column; 
        & .panel{ width: unset; margin-right: 0 !important; }
    }
}

@media (max-width: 570px){
    .mobile-hidden{ display: none !important; }
    .sideMenuBar{ display: none; }
    .body{ margin-left: 0; }
    .dashboard {
        & .panel .header{ flex-direction: column; text-align: center;
            & .btn{ margin-bottom: 10px; }
        }
        & .faturamento{ margin-left: unset !important; }
        & .multiGraph{ flex-direction: column; 
            & .panel{ width: unset; margin-right: 0 !important; }
        }
    }
    .footer{ padding: 2.5px 0; }
}

@media (min-width: 730px) {
    .linksBar { display: flex !important; }
}

@media (min-width: 768px) {
    .container { width: 750px; }
}

@media (min-width: 992px) {
    .container { width: 970px; }
}

@media (min-width: 1200px) {
    .container { width: 1200px; }
}

@media (max-height: 720px){
    .login .contentLogin{
        & .logo{ margin-top: -63px; }
        & .acessaForm {
            & h2{ margin-top: -20px; margin-bottom: 30px; }
            & .btn.entrar{ margin-top: 15px; }
        }
    }
}

@media (max-width: 855px){
    .multiInput .inputDefault{ margin-right: 0 !important; }
    .panelBody .inputDefault{ max-width: 100% !important; }
}

// Estilos base para mobile-first
@media (max-width: 768px) {
    body {
        font-size: 14px;
    }

    .container {
        padding: 0 15px;
        width: 100%;
    }

    // Menu lateral
    .sideMenuBar {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 995;
        transform: translateX(-100%);
        transition: transform 0.3s ease;
        
        &.active {
            transform: translateX(0);
        }
    }

    // Header responsivo
    .header {
        padding: 10px;
        
        .menuToggle {
            display: block;
            font-size: 24px;
            margin-right: 15px;
        }

        .headerContent {
            flex-direction: column;
            align-items: flex-start;
            
            .headerTitle {
                margin-bottom: 10px;
            }
        }
    }

    // Tabelas responsivas
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        
        table {
            width: 98%;
            th, td {
                white-space: nowrap;
                padding: 8px;
            }
        }
    }

    // Forms
    .form-group {
        margin-bottom: 15px;
        
        input, select, textarea {
            width: 100%;
            padding: 10px;
            font-size: 16px; // Evita zoom automático no iOS
        }
    }

    // Cards e Painéis
    .panel {
        padding: 15px;
        margin-bottom: 15px;
        
        .header {
            flex-direction: column;
            align-items: flex-start;
            
            .btn {
                width: 100%;
                margin-top: 10px;
            }
        }
    }

    // Botões
    .btn {
        padding: 10px 15px;
        width: 100%;
        margin-bottom: 10px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }

    // Grid responsivo
    .row {
        flex-direction: column;
        
        [class*="col-"] {
            width: 100%;
            padding: 0;
            margin-bottom: 15px;
        }
    }

    // Paginação
    .pagination {
        justify-content: center;
        flex-wrap: wrap;
        
        li {
            margin: 2px;
            
            a {
                padding: 8px 12px;
            }
        }
    }
}

// Ajustes específicos para tablets
@media (min-width: 769px) and (max-width: 1024px) {
    .container {
        width: 95%;
    }

    .panel {
        .header {
            flex-wrap: wrap;
        }
    }
}

// Estilos responsivos para tabelas em dispositivos móveis
@media screen and (max-width: 768px) {
    .table {
        tr {
            display: block;
            margin-bottom: 15px;
            border: 1px solid #eee;
            padding: 10px;
        }

        thead {
            display: none;
        }

        td {
            &[data-label] {
                display: flex;
                justify-content: space-between;
                padding: 8px 0;
                border-bottom: 1px solid #eee;

                &:before {
                    content: attr(data-label);
                    font-weight: bold;
                    margin-right: 10px;
                }
            }

            &.mobile-hidden {
                display: none;
            }

            &.rowActions {
                display: flex;
                justify-content: flex-start;
                padding-top: 10px;
                margin: 10px -10px -10px;
                padding: 10px;
                border-top: 1px solid #eee;
                background: #f5f5f5;

                &:before {
                    display: none;
                }

                button {
                    margin-right: 15px !important;
                }
            }
        }
    }

    .modalBody {
        padding: 10px;

        .form-group {
            margin-bottom: 12px;

            label {
                display: block;
                font-size: 14px;
                margin-bottom: 4px;
                color: #666;
            }

            input, select, textarea {
                width: 100%;
                font-size: 16px;
                padding: 8px 12px;
                margin-bottom: 0;
                height: 40px;
                border: 1px solid #ddd;
                border-radius: 4px;

                &:focus {
                    border-color: #0066ff;
                    outline: none;
                }
            }

            textarea {
                height: auto;
                min-height: 100px;
            }
        }
    }
}

// Ajustes para modal em dispositivos móveis
@media screen and (max-width: 768px) {
    .modal {
        .modalContent {
            margin: 0;
            border-radius: 0;
            height: 100%;
            display: flex;
            flex-direction: column;

            .modalHeader {
                top: 0;
                left: 0;
                right: 0;
                z-index: 1000;
            }
            
            .modalBody {
                padding: 25px 15px 15px 15px;

                .header {
                    text-align: center;
                    margin-bottom: 20px;

                    img {
                        margin-bottom: 10px;
                    }

                    h2 {
                        font-size: 18px;
                        margin: 0;
                    }
                }

                .form-group {
                    margin-bottom: 12px;

                    label {
                        display: block;
                        font-size: 14px;
                        margin-bottom: 4px;
                        color: #666;
                    }

                    input, select, textarea {
                        width: 100%;
                        font-size: 16px;
                        padding: 8px 12px;
                        margin-bottom: 0;
                        height: 40px;
                        border: 1px solid #ddd;
                        border-radius: 4px;

                        &:focus {
                            border-color: #0066ff;
                            outline: none;
                        }
                    }

                    textarea {
                        height: auto;
                        min-height: 100px;
                    }
                }
            }

            .modalFooter {
                background: white;
                padding: 10px 15px 0 !important;
                box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
                display: flex;
                gap: 10px;

                button {
                    flex: 1;
                    height: 45px;
                    line-height: 45px;
                    width: 125px;
                    padding: 0;
                    border-radius: 4px;
                    font-size: 16px;
                    font-weight: 500;

                    &.secondary,
                    &.primary {
                        height: 45px;
                        line-height: 45px;
                        padding: 0;
                    }

                    &.primary {
                        background-color: #000;
                        color: #fff;
                    }

                    &.secondary {
                        border: 1px solid #000;
                        color: #000;
                    }
                }
            }
        }
    }
}

// Ajustes específicos quando o teclado virtual está aberto
@media screen and (max-height: 500px) {
    .modal {
        .modalContent {
            height: auto;
            min-height: 100vh;
            
            .modalBody {
                padding-bottom: 100px;
            }
        }
    }
}